<template>
    <div class="mx-auto text-center pt-4 text-xl space-y-2">
    <input class="mx-auto w-10/12 p-2 rounded-md bg-stone-200 border-stone-700 border shadow-inner" v-model="destinationName" placeholder="First and Last Name" />
    <input class="mx-auto w-10/12 p-2 rounded-md bg-stone-200 border-stone-700 border shadow-inner" v-model="destinationEmail" placeholder="Email Address" />
    <input class="mx-auto w-10/12 p-2 rounded-md bg-stone-200 border-stone-700 border shadow-inner" v-model="destinationPhone" placeholder="Phone Number" />
    </div>
    <div class="mx-auto pt-4 text-center">
      <div @click="saveContact()" class="bg-[#ffa237] border-black border-2 text-2xl font-bold rounded-2xl mx-auto px-4 py-3 drop-shadow-3xl my-4 w-2/3 text-center">Save</div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'AddContact',
  data() {
    return{
      destinationName: '',
      destinationPhone: '',
      destinationEmail: '',
    }
  },
  emits : ['update:currentMode'],
  methods:{

    saveContact() {
    const userData = this.$auth0.user;

    if (!userData) {
        console.error('Error: User data not available from Auth0.');
        return;
    }


    const formData = new FormData();
    formData.append('name', this.destinationName);
    formData.append('email', this.destinationEmail);
    formData.append('phone', this.destinationPhone);
    formData.append('sid', 'timtest');

    axios.post('https://api.inhearten.com/api/emails/addcontact', formData, { // Correct usage: formData as the second argument
        headers: { // Important for FormData
            'Content-Type': 'multipart/form-data' // Typically Axios handles this, but it's good practice to include
        }
    })
        .then((response) => {
            console.log('Contact saved successfully:', response.data);
            window.location.reload(); 
        })
        .catch((error) => {
            console.error('Error saving contact:', error.response ? error.response.data : error.message);
            // Handle error (e.g., display error message)
        });
}
  }

}
</script>
